import { Injectable } from "@angular/core";
import { MultipleTdssRequest } from "src/app/features/tds-request/models/multiple-tdss-request.model";
import { SingleTdsRequest } from "src/app/features/tds-request/models/single-tds-request.model";
import { environment } from "src/environments/environment";
import { WindowReferenceService } from './window-reference.service';

declare let gtag: Function;
function getWindow():any{
  return window;
}


@Injectable({
  providedIn: 'root'
})

export class GoogleAnalyticsService
{
  
  private window;
  public constructor (private _windowRef: WindowReferenceService)
  {
      this.window = _windowRef.nativeWindow; // intialise the window to what we get from our window service

  }

  private pingHome(obj: any)
    {
        if(obj)  this.window.dataLayer.push(obj);
    }

    public get nativeWindow() {
      return getWindow();
    }    

    public sendInfo_toGTM(hit: any)
    {
      this.pingHome(hit);
    }

    private sendTDSReq_toGTM(eventName: string, lang: string, branding: string, article:string, v:string, timestamp: number, auth: boolean, reqtype: string)
    {
      const hit = {
        event:eventName,
        language: lang,
        theme: branding,
        article_number: article,
        version: v,
        timestamp: timestamp,
        authenthicated: auth,
        request_type: reqtype
      }
      this.pingHome(hit);
    }

    // Refactor -> Should not have a reference to singletdsrequest from feature tdsrequest
  public sendMultipleTdsRequestGtags(multipleTdssRequest: MultipleTdssRequest, requestType: string): void
  {
    var date = new Date();
    var utcDate = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(),
      date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());

    for (const tdsRequest of multipleTdssRequest.tdss)
    {
      this.sendTDSReq_toGTM('tds_request',
          multipleTdssRequest.language,
          multipleTdssRequest.branding,
          tdsRequest.articleNumber,
          tdsRequest.version,
          utcDate,
          !multipleTdssRequest.isExternal,
          requestType
        );
    }
  }

  // Refactor -> Should not have a reference to singletdsrequest from feature tdsrequest
  public sendSingleTdsRequestGtag(singleTdsRequest: SingleTdsRequest, requestType: string): void
  {
    var date = new Date();
    var utcDate = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(),
      date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());

    this.sendTDSReq_toGTM('tds_request',
    singleTdsRequest.language,
    singleTdsRequest.branding,
    singleTdsRequest.articleNumber,
    singleTdsRequest.version,
    utcDate,
    !singleTdsRequest.isExternal,
    requestType
    );
  }
}