<div class="container" [ngClass]="businessLineBaseClassName + '-container'">
  <div class="container-content">
    <div class="main-bar-container">
      <div class="language-box">
        <mat-form-field appearance="fill">
          <mat-select (selectionChange)="onLanguageSelectionChanged($event)" [value]="siteLanguage?.code"
            [disableOptionCentering]="true" panelClass="language-list-panel">
            <mat-select-trigger>
              <div class="language-list-selected-box">
                <img class="language-list-selected-box-flag" [alt]="siteLanguage?.countryCode?.toLowerCase() + '-flag'"
                  [src]="
              'assets/images/flags/' + siteLanguage?.countryCode?.toLowerCase() + '.png'" />
                <p>{{ siteLanguage?.label }}</p>
                <img class="language-list-selected-box-arrow-white" [alt]="'toggle'"
                  [src]="'assets/icons/arrow-down-white.png'" />
                <img class="language-list-selected-box-arrow-black" [alt]="'toggle'"
                  [src]="'assets/icons/arrow-down.png'" />
              </div>

            </mat-select-trigger>
            <mat-option *ngFor="let language of translationService.siteLanguages" [value]="language.code">
              <div class="language-list-box">
                <img class="language-list-box-flag" [alt]="language.countryCode.toLowerCase() + '-flag'"
                  [src]="'assets/images/flags/' + language.countryCode.toLowerCase() + '.png'" />
                <p>{{language.label }}</p>
              </div>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="login-box" *ngIf="isExternalPortal" (click)="authenticationService.loginAsync()">
        <a class="login-button">
          {{"mainComponent.login" | translate}}
        </a>
        <img fxHide.lt-md="true" class="login-image" alt="login" src="assets/icons/login.png" />
      </div>

      <div class="logout-box" *ngIf="!isExternalPortal" (click)="authenticationService.logoutAsync()">
        <a class="logout-button">
          {{"mainComponent.logout" | translate}}
        </a>
        <img fxHide.lt-md="true" class="logout-image" alt="logout" src="assets/icons/logout.png" />
      </div>
    </div>

    <ng-content></ng-content>

    <app-footer></app-footer>
  </div>
</div>