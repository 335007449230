import { TdsRequest } from "./tds-request.model";

export class SingleTdsRequest extends TdsRequest
{
    private _isExternal: boolean = false;
    private _sendAsMail: boolean = false;
    private _mailAddress: string = '';
    private _language: string = '';
    private _branding: string = '';

    public constructor(
        isExternal: boolean, sendAsMail: boolean, emailAddress: string,
        language: string, branding: string, articleNumber: string,
        version: string)
    {
        super(articleNumber, version);

        this.isExternal = isExternal;
        this.sendAsMail = sendAsMail;
        this.emailAddress = emailAddress;
        this.language = language;
        this.branding = branding;
    }

    public set isExternal(isExternal: boolean)
    {
        this._isExternal = isExternal;
    }

    public get isExternal(): boolean
    {
        return this._isExternal;
    }

    public set sendAsMail(sendAsMail: boolean)
    {
        this._sendAsMail = sendAsMail;
    }

    public get sendAsMail(): boolean
    {
        return this._sendAsMail;
    }

    public set emailAddress(emailAddress: string)
    {
        this._mailAddress = emailAddress;
    }

    public get emailAddress(): string
    {
        return this._mailAddress;
    }

    public set language(language: string)
    {
        this._language = language;
    }

    public get language(): string
    {
        return this._language;
    }

    public set branding(branding: string)
    {
        this._branding = branding;
    }

    public get branding(): string
    {
        return this._branding;
    }

    public override toJson(): any
    {
        return {
            ...{
                isExternal: this.isExternal,
                sendAsMail: this.sendAsMail,
                emailAddress: this.emailAddress,
                language: this.language,
                branding: this.branding
            },
            ...super.toJson()
        }
    }
}