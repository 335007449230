import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TdsRequestRoutingModule } from './routing/tds-request-routing.module';
import { TdsRequestComponent } from './pages/tds-request/tds-request.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { TdsRequestDownloadComponent } from './pages/tds-request-download/tds-request-download.component';

@NgModule({
  declarations: [
    TdsRequestComponent,
    TdsRequestDownloadComponent
  ],
  imports: [
    CommonModule,
    TdsRequestRoutingModule,
    SharedModule
  ]
})

export class TdsRequestModule { }