export class QueryParameterUtils {
  public static fromBase64(base64string: string): string {
    return atob(base64string);
  }

  public static toBase64(string: string): string {
    return btoa(string);
  }

  public static getLangageFormattedCode(unformattedLanguage: string): string {
    const lowerUnformattedLanguage = unformattedLanguage.toLowerCase();
    const args: string[] = lowerUnformattedLanguage.includes('-')
      ? lowerUnformattedLanguage.split('-')
      : lowerUnformattedLanguage.split('_');
    const secondPart = args[1].toUpperCase();

    return `${args[0]}_${secondPart}`;
  }
}
