import { Component, OnInit } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { BusinessLine } from '../../models/business-line.model';
import { Language } from '../../models/language.model';
import { AuthenticationService } from 'src/app/authentication/services/authentication.service';
import { TranslationService } from '../../translation/services/translation.service';
import { BusinessLineService } from '../../services/business-line.service';
import { Router } from '@angular/router';
import { QueryParameterUtils } from '../../utils/query-parameter.utils';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {
  public isExternalPortal!: boolean;
  public siteLanguages: Language[];
  public siteLanguage!: Language;
  public siteBusinessLine!: BusinessLine;
  public businessLineBaseClassName!: string;

  public constructor(
    public authenticationService: AuthenticationService,
    public translationService: TranslationService,
    private _businessLineService: BusinessLineService,
    private _router: Router
  ) {
    this.siteLanguages = [];
    this.initializeIsExternalPortal();

    this.initializeSiteLanguage();
    this.initializeSiteLanguages();
  }

  public ngOnInit(): void {}

  public ngOnDestroy(): void {
    this.authenticationService.destroyAuthenticationSubscription();
  }

  public onLanguageSelectionChanged(event: MatSelectChange): void {
    const selectedLanguage: Language | undefined = this.siteLanguages.find(
      (language) => language.code.toLowerCase() === event.value.toLowerCase()
    );

    if (selectedLanguage) {
      this.translationService.tryChangeSiteLanguage(selectedLanguage?.code);
      this._router.navigate([], {
        queryParams: {
          [environment.languageQueryParameter]: QueryParameterUtils.toBase64(
            selectedLanguage.countryCode
          ),
        },
        queryParamsHandling: 'merge',
      });
    }
  }

  private initializeIsExternalPortal(): void {
    this.authenticationService.isExternalPortal$.subscribe((result) => {
      this.isExternalPortal = result;

      this.initializeSiteBusinessLine();
    });
  }

  private initializeSiteLanguage(): void {
    this.translationService.siteLanguage$.subscribe((result) => {
      this.siteLanguage = result;
    });
  }

  private initializeSiteLanguages(): void {
    for (const language of environment.uiLanguages) {
      this.siteLanguages.push(
        new Language(language.label, language.code, language.countryCode)
      );
    }

    this.siteLanguages.sort((a, b) => a.label.localeCompare(b.label));
  }

  private initializeSiteBusinessLine(): void {
    this._businessLineService.siteBusinessLine$.subscribe((result) => {
      this.siteBusinessLine = result;

      this.businessLineBaseClassName = this.getBusinessLineBaseClassName();
    });
  }

  private getBusinessLineBaseClassName(): string {
    return this._businessLineService.getBusinessLineBaseClassName(
      this.isExternalPortal
    );
  }
}
