import { Injectable } from "@angular/core";
import
{
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { Router } from "@angular/router";
import { AppRoutingConstants } from "src/app/routing/constants/app-routing-constants";

@Injectable({
    providedIn: 'root',
})

export class ServerErrorHttpInterceptor implements HttpInterceptor
{
    private _serverErrorCodeMin: number = 500;
    private _serverErrorCodeMax: number = 511

    constructor(private _router: Router) { }

    public intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>>
    {

        return next.handle(req).pipe(
            catchError(error =>
            {
                this.handleServerError(error);

                return throwError(() => error);
            })
        );
    }

    private handleServerError(error: HttpErrorResponse): void
    {
        if (error.status >= this._serverErrorCodeMin && error.status <= this._serverErrorCodeMax)
        {
            this._router.navigate([AppRoutingConstants.SERVER_ERROR], { state: { errorCode: error.status } });
        }
    }
}