import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/authentication/services/authentication.service';
import { GoogleAnalyticsService } from 'src/app/shared/services/google-analytics.service';
import { QueryParameterUtils } from 'src/app/shared/utils/query-parameter.utils';
import { environment } from 'src/environments/environment';
import { SingleTdsRequest } from '../../models/single-tds-request.model';
import { SingleTdsResponse } from '../../models/single-tds-response.model';
import { TdsRequestRoutingConstants } from '../../routing/constants/tds-request-routing-constants';
import { TdsRequestDataService } from '../../services/tds-request-data.service';
import { BrandingUtils } from 'src/app/shared/utils/branding-utils';

@Component({
  selector: 'app-tds-request-download',
  templateUrl: './tds-request-download.component.html',
  styleUrls: ['./tds-request-download.component.scss']
})

export class TdsRequestDownloadComponent implements OnInit
{
  private _tdsRequest: SingleTdsRequest;

  public constructor(
    private _authenticationService: AuthenticationService,
    private _tdsRequestDataService: TdsRequestDataService,
    private _googleAnalyticsService: GoogleAnalyticsService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _title: Title)
  {
    this._title.setTitle("TDS Portal - Download");

    this._tdsRequest = new SingleTdsRequest(false, false, '', '', '', '', '');
  }

  public ngOnInit(): void
  {
    this._authenticationService.isExternalPortal$.subscribe(result =>
    {
      this._tdsRequest.isExternal = result;

      this.handleQueryParameters();

      this.showTdsAsync();

      this.navigateToTdsRequestPage();
    });
  }

  private async showTdsAsync(): Promise<void>
  {
    const tdsResponse: SingleTdsResponse = await this._tdsRequestDataService.getSingleTdsAsync(this._tdsRequest);

    this._googleAnalyticsService.sendSingleTdsRequestGtag(this._tdsRequest, "download_link");

    var windowSize = "width=" + window.innerWidth + ",height=" + window.innerHeight + ",scrollbars=no";
    const pdfWindow = window.open("", "_blank", windowSize);

    pdfWindow?.document.write("<html<head><title>" + tdsResponse.externalFileName + "</title><style>body{margin: 0px;}iframe{border-width: 0px;}</style></head>");
    pdfWindow?.document.write("<body><embed width='100%' height='100%' src='data:application/pdf;base64, " + tdsResponse.pdf + "#toolbar=1&navpanes=1&scrollbar=1'></embed></body></html>");
  }

  private handleQueryParameters(): void
  {
    this._route.queryParams.subscribe(params =>
    {
      if (params[environment.languageQueryParameter])
      {
        this._tdsRequest.language = QueryParameterUtils.fromBase64(params[environment.languageQueryParameter]).replace('-', '_');
      }

      if (params[environment.companyThemeQueryParameter])
      {
        this._tdsRequest.branding = BrandingUtils.fromAlias(QueryParameterUtils.fromBase64(params[environment.companyThemeQueryParameter]).toLowerCase());
      }

      if (params[environment.articleIdQueryParameter])
      {
        this._tdsRequest.articleNumber = QueryParameterUtils.fromBase64(params[environment.articleIdQueryParameter]);
      }

      if (params[environment.versionQueryParameter])
      {
        this._tdsRequest.version = QueryParameterUtils.fromBase64(params[environment.versionQueryParameter]);
      }
    });
  }

  private navigateToTdsRequestPage(): void
  {
    this._router.navigate([TdsRequestRoutingConstants.TDS_REQUEST])
  }
}