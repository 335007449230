import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss']
})
export class TermsOfUseComponent implements OnInit
{
  public constructor(private _dialogRef: MatDialogRef<TermsOfUseComponent>)
  {
  }

  public ngOnInit(): void
  {
  }

  public ngOnDestroy(): void
  {
  }

  public onCloseClicked(): void
  {
    this._dialogRef.close();
  }
}
