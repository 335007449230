import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TdsRequest } from '../../models/tds-request.model';
import * as XLSX from 'xlsx';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { QueryParameterUtils } from 'src/app/shared/utils/query-parameter.utils';
import { Language } from 'src/app/shared/models/language.model';
import { MatDialog } from '@angular/material/dialog';
import { TermsOfUseComponent } from 'src/app/shared/components/terms-of-use/terms-of-use.component';
import { CompanyTheme } from '../../models/company-theme.model';
import { BusinessLineService } from 'src/app/shared/services/business-line.service';
import { BusinessLine } from 'src/app/shared/models/business-line.model';
import { MultipleTdssResponse } from '../../models/multiple-tdss-response.model';
import { TdsRequestDataService } from '../../services/tds-request-data.service';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { SingleTdsRequest } from '../../models/single-tds-request.model';
import { SingleTdsResponse } from '../../models/single-tds-response.model';
import { MultipleTdssRequest } from '../../models/multiple-tdss-request.model';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'src/app/authentication/services/authentication.service';
import { TranslationService } from 'src/app/shared/translation/services/translation.service';
import { Title } from '@angular/platform-browser';
import { GoogleAnalyticsService } from 'src/app/shared/services/google-analytics.service';
import { MatSelectChange } from '@angular/material/select';
import { PopupNotAllowedError } from '../../exceptions/popup-not-allowed.error';
import { BrandingUtils } from 'src/app/shared/utils/branding-utils';

@Component({
  selector: 'app-tds-request',
  templateUrl: './tds-request.component.html',
  styleUrls: ['./tds-request.component.scss']
})

export class TdsRequestComponent implements OnInit {
  public isExternal!: boolean;
  public siteLanguage!: Language;
  public preferredLanguage!: Language
  public siteBusinessLine!: BusinessLine;
  public businessLineBaseClassName!: string;
  public landingImageUrl!: string;

  public multipleTdssRequestForm: FormGroup;

  public preferredLanguages: Language[];
  public brandings: CompanyTheme[];

  public fileName: string;

  public errorMessage: string;

  public isLoading: boolean;

  public tdsRetrievalStatuses: (boolean | null)[];
  public tdsDownloadErrorMessage: string;

  public emailSuccessfullySent: boolean;

  public constructor(
    private _authenticationService: AuthenticationService,
    private _tdsRequestDataService: TdsRequestDataService,
    private _businessLineService: BusinessLineService,
    private _translateService: TranslateService,
    private _translationService: TranslationService,
    private _googleAnalyticsService: GoogleAnalyticsService,
    private _dialog: MatDialog,
    private _route: ActivatedRoute,
    private _router: Router,
    private _formBuilder: FormBuilder,
    private _title: Title) {
    this._title.setTitle("TDS Portal - Productinfo");

    this.preferredLanguages = [];
    this.brandings = [];

    this.fileName = '';

    this.errorMessage = '';

    this.isLoading = false;

    this.tdsRetrievalStatuses = [];
    this.tdsDownloadErrorMessage = '';

    this.multipleTdssRequestForm = this.createForm();

    this.emailSuccessfullySent = false;

    this.addNewTdsRequest();

    this.initializeIsExternalPortal();

    this.initializeLanguages();
    this.initializeBrandings();
  }

  public ngOnInit(): void {
    this._translationService.siteLanguage$.subscribe(result => {
      this.siteLanguage = result;
      this.preferredLanguage = this.siteLanguage;

      this.handleUiLanguage();
      this.handleQueryParameters();
    });
  }

  public ngOnDestroy(): void {
  }

  public get tdss(): FormArray {
    return this.multipleTdssRequestForm.controls["tdss"] as FormArray;
  }

  public getMaxAmountOfTDSs(): number {
    return this.isExternal ? environment.maxTdsRequestsForExternal : environment.maxTdsRequestsForInternal;
  }

  public addTdsRequest(tdsRequest: TdsRequest): void {
    if (this.canAddTdsRequest()) {
      this.resetErrorsAndSuccesses();

      let tdsForm: FormGroup;

      tdsForm = this._formBuilder.group({
        articleNumber: [tdsRequest.articleNumber, [Validators.required]],
        version: [tdsRequest.version, []]
      });

      this.tdss.push(tdsForm);
    }
  }

  public addNewTdsRequest(): void {
    if (this.canAddTdsRequest()) {
      this.resetErrorsAndSuccesses();

      let tdsForm: FormGroup;

      tdsForm = this._formBuilder.group({
        articleNumber: ['', [Validators.required]],
        version: ['', []]
      });

      this.tdss.push(tdsForm);
    }
  }

  public removeTdsRequest(tdsRequest: TdsRequest): void {
    this.resetErrorsAndSuccesses();

    this.tdss.removeAt(this.tdss.value.indexOf(tdsRequest));
  }

  public onArticleNumberChanged(event: Event): void {
    this.resetErrorsAndSuccesses();
  }

  public onVersionChanged(event: Event): void {
    this.resetErrorsAndSuccesses();
  }

  public onPreferredLanguageChanged(event: MatSelectChange): void {
    this.resetErrorsAndSuccesses();
    this._router.navigate([], {
      queryParams: {
        dl: QueryParameterUtils.toBase64(event.value),
      },
      queryParamsHandling: 'merge',
    });
    const selectedLanguage: Language | undefined = this.preferredLanguages.find(
      (language) => language.code.toLowerCase() === event.value.toLowerCase()
    );
    if (selectedLanguage) {
      this.preferredLanguage = selectedLanguage;
    }
  }

  public async onShowTdsRequestClickedAsync(tdsRequest: TdsRequest): Promise<void> {
    var pdfWindow: Window | null = null;
    try {
      this.resetErrorsAndSuccesses();

      this.isLoading = true;

      for (const tds in this.tdss.value) {
        this.tdsRetrievalStatuses.push(null);
      }

      const singleTdsRequest = new SingleTdsRequest(
        this.isExternal,
        false,
        '',
        this.multipleTdssRequestForm.value.language,
        this.multipleTdssRequestForm.value.branding,
        tdsRequest.articleNumber,
        tdsRequest.version
      );

      if (this.isChromeOnAndroid()) {
        const tdsResponse: SingleTdsResponse = await this._tdsRequestDataService.getSingleTdsAsync(singleTdsRequest);
        this.downloadTds(tdsResponse);
        return;
      }

      pdfWindow = this.openNewPdfWindow();
      this.displayLoadingWheel(pdfWindow);

      const tdsResponse: SingleTdsResponse = await this._tdsRequestDataService.getSingleTdsAsync(singleTdsRequest);

      this._googleAnalyticsService.sendSingleTdsRequestGtag(singleTdsRequest, "show");

      this.displayPDFContent(pdfWindow, tdsResponse);

      this.tdsRetrievalStatuses[this.tdss.value.indexOf(tdsRequest)] = true;
    } catch (error: any) {
      this.tdsDownloadErrorMessage = error.error;

      this.errorMessage = this.getErrorTranslatedMessage(error);
      this.tdsRetrievalStatuses[this.tdss.value.indexOf(tdsRequest)] = false;

      if (pdfWindow && !pdfWindow.closed) {
        pdfWindow.close();
      }

    } finally {
      this.isLoading = false;
    }
  }

  private isChromeOnAndroid(): boolean {
    const userAgent = navigator.userAgent || navigator.vendor;

    // Determine if the current browser is Google Chrome.
    const isChrome = /Chrome/.test(userAgent) && !/Chromium/.test(userAgent) && !/Edge/.test(userAgent) && !/OPR/.test(userAgent);

    // Determine if the current OS is Android
    const isAndroid = /Android/.test(userAgent);

    return isChrome && isAndroid;
  }

  private openNewPdfWindow(): Window | null {

    const windowSize = "width=" + window.innerWidth + ",height=" + window.innerHeight + ",scrollbars=no";
    const pdfWindow = window.open("about:blank", "_blank", windowSize);

    if (pdfWindow) {
      pdfWindow.opener = null;
    }
    if (!pdfWindow || pdfWindow.closed || typeof pdfWindow.closed === 'undefined') {
      throw new PopupNotAllowedError('Please enable pop-ups to view the TDS.');
    }
    return pdfWindow;
  }

  private displayLoadingWheel(window: Window | null): void {
    const loadingHTML = `
    <style>
        #loadingWheelContainer {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100vh;
        }
    </style>
    <div id="loadingWheelContainer">
        <img id="loadingWheel" src="/assets/images/loading/spinning-loading.gif" alt="Loading..." />
    </div>
    `;

    window?.document.write(loadingHTML);
  }

  private displayPDFContent(pdfWindow: Window | null, tdsResponse: SingleTdsResponse): void {
    pdfWindow?.document.open();
    pdfWindow?.document.close();

    pdfWindow?.document.write("<html<head><title>" + tdsResponse.externalFileName + "</title><style>body{margin: 0px;}iframe{border-width: 0px;}</style></head>");
    //pdfWindow?.document.write("<body><iframe width='100%' height='100%' src='data:application/pdf;base64," + tdsResponse.pdf + "#toolbar=1&navpanes=1&scrollbar=1'></iframe></body></html>");
    pdfWindow?.document.write("<body><embed width='100%' height='100%' src='data:application/pdf;base64," + tdsResponse.pdf + "'/></body></html>");
  }

  private getErrorTranslatedMessage(error: Error): string {
    const errorMEssage = (error instanceof PopupNotAllowedError) ? 'Please enable pop-ups to view the TDS.' : this._translateService.instant('tdsRequestComponent.tdsCouldNotBeShown');
    return errorMEssage;
  }

  public canAddTdsRequest(): boolean {
    return this.tdss.length < this.getMaxAmountOfTDSs();
  }

  public onFileUploaded(event: any) {
    this.resetErrorsAndSuccesses();

    const file: File = event.target.files[0];

    if (file) {
      this.fileName = file.name;

      const fileReader = new FileReader();

      fileReader.readAsBinaryString(file);

      fileReader.onload = (event: ProgressEvent<FileReader>) => {
        this.tdss.clear();

        const data = event.target!.result;

        const workbook = XLSX.read(data, { type: 'binary' });

        const sheet = workbook.Sheets[workbook.SheetNames[0]];

        const parsedData = XLSX.utils.sheet_to_json(sheet);

        const convertedJson = JSON.stringify(parsedData);

        const tdsRequests: TdsRequest[] = JSON.parse(convertedJson);

        tdsRequests.map(tdsRequest => {
          tdsRequest.articleNumber = tdsRequest.articleNumber?.toString(),
            tdsRequest.version = tdsRequest.version?.toString()
        });

        tdsRequests.forEach((tdsRequest: TdsRequest) => {
          this.addTdsRequest(tdsRequest);
        });

        if (this.tdss.length === 0) {
          this.addNewTdsRequest();
        }
      }
    }
  }

  public openTermsOfUseDialog() {
    const dialogRef = this._dialog.open(TermsOfUseComponent);
  }

  public checkIfTdsDownloadAttempted(index: number): boolean {
    return this.tdsRetrievalStatuses[index] != null;
  }

  public checkIfTdsCouldBeDownloaded(index: number): boolean {
    return this.tdsRetrievalStatuses[index] ?? false;
  }

  public async onDownloadClickedAsync(): Promise<void> {
    try {
      this.resetErrorsAndSuccesses();

      this.isLoading = true;

      const multipleTdssRequest = new MultipleTdssRequest(
        this.isExternal,
        this.multipleTdssRequestForm.value.sendAsMail,
        this.multipleTdssRequestForm.value.emailAddress,
        this.multipleTdssRequestForm.value.language,
        this.multipleTdssRequestForm.value.branding,
        this.multipleTdssRequestForm.value.tdss,
      );

      const MultipleTdssResponse: MultipleTdssResponse = await this._tdsRequestDataService.getMultipleTdssAsync(multipleTdssRequest);

      this._googleAnalyticsService.sendMultipleTdsRequestGtags(multipleTdssRequest, "download");

      for (const tdsResponse of MultipleTdssResponse.tdsResponses) {
        this.downloadTdsAndUpdateStatus(tdsResponse);

        await new Promise(resolve => setTimeout(resolve, 200));
      }
    } catch (error: any) {
      this.errorMessage = this._translateService.instant('tdsRequestComponent.downloadsCouldNotBePerformed');

      for (const tds of this.multipleTdssRequestForm.value.tdss) {
        this.tdsRetrievalStatuses.push(false);
        this.tdsDownloadErrorMessage = "Server issue";
      }
    }
    finally {
      this.isLoading = false;
    }
  }


  private downloadTdsAndUpdateStatus(tdsResponse: SingleTdsResponse): void {
    if (tdsResponse.pdf) {
      this.downloadTds(tdsResponse);
      this.tdsRetrievalStatuses.push(true);
    } else {
      this.tdsRetrievalStatuses.push(false);
      this.tdsDownloadErrorMessage = tdsResponse.errorMessage;
    }
  }


  private downloadTds(tdsResponse: SingleTdsResponse): void {
    const link: HTMLAnchorElement = document.createElement('a');
    link.style.display = 'none';
    link.href = `data:application/octet-stream;base64,${tdsResponse.pdf}`;
    link.download = tdsResponse.externalFileName;
    link.setAttribute('download', tdsResponse.externalFileName);
    if (typeof link.download === 'undefined') {
      link.setAttribute('target', '_blank');
    }

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  }

  public async onSendClickedAsync(): Promise<void> {
    try {
      this.resetErrorsAndSuccesses();

      this.isLoading = true;

      const multipleTdssRequest = new MultipleTdssRequest(
        this.isExternal,
        this.multipleTdssRequestForm.value.sendAsMail,
        this.multipleTdssRequestForm.value.emailAddress,
        this.multipleTdssRequestForm.value.language,
        this.multipleTdssRequestForm.value.branding,
        this.multipleTdssRequestForm.value.tdss,
      );

      const MultipleTdssResponse: MultipleTdssResponse = await this._tdsRequestDataService.getMultipleTdssAsync(multipleTdssRequest);

      this._googleAnalyticsService.sendMultipleTdsRequestGtags(multipleTdssRequest, "mail");

      this.emailSuccessfullySent = true;
    } catch (error: any) {
      this.emailSuccessfullySent = false;

      this.errorMessage = this._translateService.instant('tdsRequestComponent.emailCouldNotBeSent');
    } finally {
      this.isLoading = false;
    }
  }

  public getErrorMessage(errors: any) {
    if (!errors) {
      return null;
    }

    if (errors.required) {
      return this._translateService.instant('tdsRequestComponent.isRequired');
    }

    if (errors.multipleEmail) {
      return this._translateService.instant('tdsRequestComponent.isNotValid');
    }

    return null;
  }

  public resetErrorsAndSuccesses(): void {
    this.tdsRetrievalStatuses.length = 0;
    this.tdsDownloadErrorMessage = '';

    this.emailSuccessfullySent = false;

    this.errorMessage = '';
  }

  private initializeIsExternalPortal(): void {
    this._authenticationService.isExternalPortal$.subscribe(result => {
      this.isExternal = result;

      this.initializeSiteBusinessLine();
    });
  }

  private initializeSiteBusinessLine(): void {
    this._businessLineService.siteBusinessLine$.subscribe(result => {
      this.siteBusinessLine = result;

      this.landingImageUrl = this.getLandingImageUrl();

      this.businessLineBaseClassName = this.getBusinessLineBaseClassName();
    });
  }

  private getBusinessLineBaseClassName(): string {
    return this._businessLineService.getBusinessLineBaseClassName(this.isExternal);
  }

  private initializeLanguages(): void {
    for (const language of environment.tdsLanguages) {
      this.preferredLanguages.push(new Language(language.label, language.code, language.countryCode));
    }

    this.preferredLanguages.sort((a, b) => a.label.localeCompare(b.label));
  }

  private initializeBrandings(): void {
    for (const companyTheme of environment.companyThemes) {
      this.brandings.push(new CompanyTheme(companyTheme.label, companyTheme.code));
    }

    this.brandings.sort((a, b) => a.label.localeCompare(b.label));
  }

  private getLandingImageUrl(): string {
    if (!this.isExternal) {
      return `assets/images/landing/general-internal.png`;
    }

    return `assets/images/landing/${this.siteBusinessLine.code}-external.png`;
  }

  private createForm(): FormGroup {
    const formGroup = this._formBuilder.group({
      sendAsMail: [false, [Validators.required]],
      emailAddress: ['', []],
      language: ['', []],
      branding: ['', []],
      tdss: this._formBuilder.array([]),
    });

    formGroup.get('sendAsMail')?.valueChanges.subscribe(value => {
      if (value) {
        formGroup.get('emailAddress')?.addValidators([Validators.required, this.multipleEmailValidator()]);
      }
      else {
        formGroup.get('emailAddress')?.setValidators(null);
        formGroup.get('emailAddress')?.setErrors(null);
      }

      formGroup.updateValueAndValidity();
    });

    return formGroup;
  }

  private multipleEmailValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value: string = control.value;

      if (!value) {
        return null;
      }

      const emailAddresses: string[] = value.split(environment.mailAddressSeparator);

      let isValid: boolean = false;

      if (!(this.isExternal && emailAddresses.length > 1)) {
        const regex = /([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|"([]!#-[^-~ \t]|(\\[\t -~]))+")@[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?(\.[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?)+/;

        for (const emailAddress of emailAddresses) {
          isValid = regex.test(emailAddress);

          if (!isValid) {
            break;
          }
        }
      }

      return !isValid ? { multipleEmail: true } : null;
    }
  }

  private handleUiLanguage(): void {
    if (this.preferredLanguages.find(l => l.code == this.siteLanguage.code)) {
      this.multipleTdssRequestForm.patchValue({ language: this.siteLanguage.code });
    }
  }

  private handleQueryParameters(): void {
    this._route.queryParams.subscribe(params => {
      if (params[environment.documentlanguageQueryParameter]) {
        const languageParam: string = QueryParameterUtils.getLangageFormattedCode(QueryParameterUtils.fromBase64(params[environment.documentlanguageQueryParameter]));
        const selectedLanguage: Language | undefined = this.preferredLanguages.find((language) => language.code.toLowerCase() === languageParam.toLowerCase());
        if (selectedLanguage) {
          this.preferredLanguage = selectedLanguage
          this.multipleTdssRequestForm.patchValue({ language: QueryParameterUtils.getLangageFormattedCode(languageParam) });
        }
      }
      else {
        if (params[environment.languageQueryParameter]) {
          const languageParam: string = QueryParameterUtils.fromBase64(params[environment.languageQueryParameter]);
          const selectedLanguage: Language | undefined = this._translationService.siteLanguages.find((language) => language.countryCode.toLowerCase() === languageParam.toLowerCase());
          if (selectedLanguage) {
            const tdsLanguage = this.preferredLanguages.find((language) => language.code.toLowerCase() === selectedLanguage.code.toLowerCase())
            if (tdsLanguage) {
              this.preferredLanguage = tdsLanguage
              this.multipleTdssRequestForm.patchValue({ language: QueryParameterUtils.getLangageFormattedCode(tdsLanguage.code) });
            }
          }
        }
      }

      this.handleBrandingParameter(params);

      if (params[environment.emailQueryParameter]) {
        this.multipleTdssRequestForm.patchValue({ sendAsMail: true });

        this.multipleTdssRequestForm.patchValue({ emailAddress: QueryParameterUtils.fromBase64(params[environment.emailQueryParameter]) });
      }

      if (params[environment.articleIdQueryParameter]) {
        this.tdss.at(0).patchValue({ articleNumber: QueryParameterUtils.fromBase64(params[environment.articleIdQueryParameter]) });
      }

      if (params[environment.articleIdUnencodedQueryParameter]) {
        this.tdss.at(0).patchValue({ articleNumber: params[environment.articleIdUnencodedQueryParameter] });
      }

      if (params[environment.versionQueryParameter]) {
        this.tdss.at(0).patchValue({ version: QueryParameterUtils.fromBase64(params[environment.versionQueryParameter]) });
      }

      if (params[environment.versionUnencodedQueryParameter]) {
        this.tdss.at(0).patchValue({ version: params[environment.versionUnencodedQueryParameter] });
      }
    });
  }

  private handleBrandingParameter(params: Params) {
    const brandingCode = params[environment.companyThemeQueryParameter] ?
      BrandingUtils.fromAlias(QueryParameterUtils.fromBase64(params[environment.companyThemeQueryParameter]))
      : BrandingUtils.getDefault();

    const selectedBranding: CompanyTheme | unknown = environment.companyThemes.find((branding) => branding.code.toLowerCase() === brandingCode.toLowerCase());

    this.multipleTdssRequestForm.patchValue({ CompanyTheme: selectedBranding });
    this.multipleTdssRequestForm.patchValue({ branding: brandingCode });

    this.tdss.at(0).patchValue({ CompanyTheme: brandingCode.toLowerCase() });
    this.tdss.at(0).patchValue({ branding: brandingCode.toLowerCase() });
  }
}