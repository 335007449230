export class CompanyTheme
{
    private _label: string = '';
    private _code: string = '';

    public constructor(label: string, code: string)
    {
        this.label = label;
        this.code = code;
    }

    public set label(label: string)
    {
        this._label = label;
    }

    public get label(): string
    {
        return this._label;
    }

    public set code(code: string)
    {
        this._code = code;
    }

    public get code(): string
    {
        return this._code;
    }
}