import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { ServerErrorComponent } from './pages/server-error/server-error.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';



@NgModule({
  declarations: [
    PageNotFoundComponent,
    ServerErrorComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  providers: [
  ],
  exports: []
})

export class CoreModule { }
