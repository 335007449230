import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { GoogleAnalyticsService } from './shared/services/google-analytics.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent
{
  public isIframe: boolean;

  public constructor(
    private _googleAnalyticsService: GoogleAnalyticsService,
    private _router: Router)
  {
    this.isIframe = false;

    this.setUpAnalytics();
    this.setUpCookiePro();
  }

  public ngOnInit(): void
  {
    this.setIsIframe();
  }

  private setIsIframe(): void
  {
    this.isIframe = window !== window.parent && !window.opener;
  }

  private setUpAnalytics(): void
  {
    // const script: string = `<script async src="https://www.googletagmanager.com/gtag/js?id=${environment.googleAnalyticsMeasurementId}"></script><script>window.dataLayer = window.dataLayer || [];function gtag() { dataLayer.push(arguments); }gtag('js', new Date());</script>`;

    // document.getElementsByTagName('head')[0].innerHTML += script;

    this._router.events.subscribe(event =>
    {
      if (event instanceof NavigationEnd)
      {
        const hit = {
          event: 'config',
          page_path: event.urlAfterRedirects
        }
        this._googleAnalyticsService.sendInfo_toGTM(hit);
      }
    })
  }

  private setUpCookiePro(): void
  {
    // const script: string = `<script src="https://cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js" type="text/javascript" charset="UTF-8" data-domain-script="${environment.cookieProId}"></script><script type="text/javascript">function OptanonWrapper() { }</script>`;

    // document.getElementsByTagName('head')[0].innerHTML += script;
  }
}