import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/authentication/services/authentication.service';
import { BusinessLineService } from 'src/app/shared/services/business-line.service';

@Component({
  selector: 'app-server-error',
  templateUrl: './server-error.component.html',
  styleUrls: ['./server-error.component.scss']
})

export class ServerErrorComponent implements OnInit
{
  public isExternalPortal!: boolean;
  public businessLineBaseClassName!: string;

  public errorCode: string;

  public constructor(
    public authenticationService: AuthenticationService,
    private _businessLineService: BusinessLineService,
    private _router: Router,
    private _title: Title)
  {
    this._title.setTitle("TDS Portal - Error");

    this.errorCode = '';

    this.initializeIsExternalPortal();

    this.errorCode = this.getErrorCode();
  }

  public ngOnInit(): void
  {
  }

  private initializeIsExternalPortal(): void
  {
    this.authenticationService.isExternalPortal$.subscribe(result =>
    {
      this.isExternalPortal = result;

      this.businessLineBaseClassName = this.getBusinessLineBaseClassName();
    });
  }

  private getBusinessLineBaseClassName(): string
  {
    return this._businessLineService.getBusinessLineBaseClassName(this.isExternalPortal);
  }

  private getErrorCode(): string
  {
    const routeState = this._router.getCurrentNavigation()?.extras.state;

    if (routeState)
    {
      return routeState['errorCode'];
    }

    return '500';
  }
}