import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { BusinessLine } from '../models/business-line.model';
import { QueryParameterUtils } from '../utils/query-parameter.utils';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BusinessLineService
{
  public siteBusinessLines: BusinessLine[];

  public siteBusinessLine$: Observable<BusinessLine>;
  private _siteBusinessLine$: ReplaySubject<BusinessLine>;
  private _siteBusinessLine!: BusinessLine;

  public constructor(
    private _route: ActivatedRoute
  )
  {
    this.siteBusinessLines = [];

    this._siteBusinessLine$ = new ReplaySubject<BusinessLine>(1);
    this.siteBusinessLine$ = this._siteBusinessLine$.asObservable();

    this.initializeSiteBusinessLines();

    this.setInitialSiteBusinessLine();

    this.handleQueryParameter();
  }

  public tryChangeSiteBusinessLine(businessLineCode: string): boolean
  {
    try
    {
      const selectedBusinessLine: BusinessLine | undefined = this.siteBusinessLines.find(
        (businessLine) => businessLine.code.toLowerCase() === businessLineCode.toLowerCase()
      );

      if (selectedBusinessLine)
      {
        this._siteBusinessLine = selectedBusinessLine;

        this._siteBusinessLine$.next(this._siteBusinessLine);

        return true;
      }

      return false;
    } catch (exception: any)
    {
      return false;
    }
  }

  public getBusinessLineBaseClassName(isExternalPortal: boolean): string
  {
    if (!isExternalPortal)
    {
      return `general-internal`;
    }

    return `${this._siteBusinessLine.code}-external`;
  }

  private initializeSiteBusinessLines(): void
  {
    for (const businessLine of environment.businessLines)
    {
      this.siteBusinessLines.push(
        new BusinessLine(businessLine.label, businessLine.code)
      );
    }
  }

  private setInitialSiteBusinessLine(): void
  {
    if (this.tryChangeSiteBusinessLine(environment.defaultBusinessLine)) return;

    this.tryChangeSiteBusinessLine('general');
  }

  private handleQueryParameter(): void
  {
    this._route.queryParams.subscribe((params) =>
    {
      if (params[environment.businessLineQueryParameter])
      {
        const businessLine: string = QueryParameterUtils.fromBase64(
          params[environment.businessLineQueryParameter]
        );

        this.tryChangeSiteBusinessLine(businessLine);
      }
    });
  }
}
