import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './components/footer/footer.component';
import { httpInterceptorProviders } from './interceptors/http-interceptor-registrations';
import { MainComponent } from './components/main/main.component';
import { LayoutModule } from './modules/layout/layout.module';
import { TranslationModule } from './translation/translation.module';
import { CookieStatementComponent } from './components/cookie-statement/cookie-statement.component';
import { TermsOfUseComponent } from './components/terms-of-use/terms-of-use.component';
import { CookieBannerComponent } from './components/cookie-banner/cookie-banner.component';
import { AuthenticationModule } from '../authentication/authentication.module';
import { BusinessLineService } from './services/business-line.service';
import { ApiAuthenticationModule } from '../api-authentication/api-authentication.module';
import { Title } from '@angular/platform-browser';
import { GoogleAnalyticsService } from './services/google-analytics.service';


@NgModule({
  declarations: [
    MainComponent,
    FooterComponent,
    CookieBannerComponent,
    CookieStatementComponent,
    TermsOfUseComponent,
  ],
  imports: [
    CommonModule,
    LayoutModule,
    TranslationModule,
    AuthenticationModule,
    ApiAuthenticationModule
  ],
  providers: [
    httpInterceptorProviders,
    BusinessLineService,
    Title,
    GoogleAnalyticsService
  ],
  exports: [
    LayoutModule,
    TranslationModule,
    MainComponent,
    FooterComponent
  ]
})

export class SharedModule { }