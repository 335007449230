export class TdsRequest 
{
    private _articleNumber: string = '';
    private _version: string = '';

    public constructor(articleNumber: string, version: string)
    {
        this.articleNumber = articleNumber;
        this.version = version;
    }

    public set articleNumber(articleNumber: string)
    {
        this._articleNumber = articleNumber;
    }

    public get articleNumber(): string
    {
        return this._articleNumber;
    }

    public set version(version: string)
    {
        this._version = version;
    }

    public get version(): string
    {
        return this._version;
    }

    public toJson(): any
    {
        return {
            articleNumber: this.articleNumber,
            version: this.version
        };
    }
}