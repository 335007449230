import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-cookie-banner',
  templateUrl: './cookie-banner.component.html',
  styleUrls: ['./cookie-banner.component.scss']
})

export class CookieBannerComponent implements OnInit
{
  public constructor(private _dialogRef: MatDialogRef<CookieBannerComponent>)
  {
  }

  public ngOnInit(): void
  {
  }

  public onAcceptClicked(): void
  {
    this._dialogRef.close({ areCookiesAllowed: true });
  }

  public onDeclineClicked(): void
  {
    this._dialogRef.close({ areCookiesAllowed: false });
  }
}
