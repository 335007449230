<div class="container">
    <div fxHide.lt-md="true">
        <button class="close-button" mat-button (click)="onCloseClicked()">{{ "termsOfUseComponent.close" |
            translate}}</button>
    </div>
    <div fxHide.gt-sm="true">
       <img class="close-image" alt="close-image" src="assets/icons/close.png" mat-button (click)="onCloseClicked()"/>
    </div>
    <div>
        <p class="title" mat-dialog-title>{{ "termsOfUseComponent.title" | translate}}</p>
        <mat-dialog-content>
            <p class="text" [innerHtml]="'termsOfUseComponent.termsOfUse' | translate"></p>
        </mat-dialog-content>
    </div>
</div>