import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { TdsRequestDownloadComponent } from "../pages/tds-request-download/tds-request-download.component";
import { TdsRequestComponent } from "../pages/tds-request/tds-request.component";
import { TdsRequestRoutingConstants } from "./constants/tds-request-routing-constants";

const routes: Routes = [
    {
        path: TdsRequestRoutingConstants.EMPTY,
        redirectTo: TdsRequestRoutingConstants.TDS_REQUEST,
        pathMatch:'full'
    },
    {
        path: TdsRequestRoutingConstants.TDS_REQUEST,
        component: TdsRequestComponent,
        pathMatch: 'full',
        data: {
            preload: true
        }
    },
    {
        path: TdsRequestRoutingConstants.TDS_REQUEST_DOWNLOAD,
        component: TdsRequestDownloadComponent,
        pathMatch: 'full',
        data: {
            preload: true
        }
    }
]

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ]
})

export class TdsRequestRoutingModule { }