export class AuthenticationResponse
{
    private _accessToken: string = '';

    public constructor(accessToken: string)
    {
        this.accessToken = accessToken;
    }

    public set accessToken(accessToken: string)
    {
        this._accessToken = accessToken;
    }

    public get accessToken(): string
    {
        return this._accessToken;
    }

    public static fromJson(json: any): AuthenticationResponse
    {
        return new AuthenticationResponse(json.accessToken);
    }
}