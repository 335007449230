import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from '../core/pages/page-not-found/page-not-found.component';
import { ServerErrorComponent } from '../core/pages/server-error/server-error.component';
import { AppRoutingConstants } from './constants/app-routing-constants';
import { CustomPreloadingStrategy } from './preloading/custom-preloading-strategy';

const routes: Routes = [
  {
    path: AppRoutingConstants.SERVER_ERROR,
    component: ServerErrorComponent,
    pathMatch: 'full',
    data: {
      preload: true
    }
  },
  {
    path: AppRoutingConstants.WILDCARD,
    component: PageNotFoundComponent,
    pathMatch: 'full',
    data: {
      preload: true
    }
  }
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: CustomPreloadingStrategy,
      initialNavigation: !isIframe ? 'enabled' : 'disabled' // Don't perform initial navigation in iframes
    })
  ],
  providers:[CustomPreloadingStrategy],
  exports: [RouterModule]
})

export class AppRoutingModule { }
