export class SingleTdsResponse
{
    private _fileName: string = '';
    private _externalFileName: string = '';
    private _pdf: Uint8Array = new Uint8Array;
    private _errorMessage:string='';

    public constructor(fileName: string, externalFileName: string, pdf: Uint8Array,errorMessage:string)
    {
        this.fileName = fileName;
        this.externalFileName = externalFileName;
        this.pdf = pdf;
        this.errorMessage=errorMessage;
    }

    public set fileName(fileName: string)
    {
        this._fileName = fileName;
    }

    public get fileName(): string
    {
        return this._fileName;
    }

    public set externalFileName(externalFileName: string)
    {
        this._externalFileName = externalFileName;
    }

    public get externalFileName(): string
    {
        return this._externalFileName;
    }

    public set pdf(pdf: Uint8Array)
    {
        this._pdf = pdf;
    }

    public get pdf(): Uint8Array
    {
        return this._pdf;
    }

    public set errorMessage(errorMessage: string)
    {
        this._errorMessage = errorMessage;
    }

    public get errorMessage(): string
    {
        return this._errorMessage;
    }

    public static fromJson(json:any):SingleTdsResponse{
        return new SingleTdsResponse(
            json.fileName,
            json.externalFileName,
            json.pdf,
            json.errorMessage
        );
    }
}