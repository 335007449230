export class BrandingUtils {
    private static aliases: Record<string, string> = {
        'crf': 'croustico',
        'crco': 'croustico',
    };

    private static defaultBranding: string = 'corporate';

    public static fromAlias(alias: string): string {
        return BrandingUtils.aliases[alias.toLowerCase()] || alias;
    }

    public static getDefault(): string {
        return BrandingUtils.defaultBranding;
    }
}