<div fxHide.lt-md="true" class="container">
    <div class="left-box">
        <p>&copy; <span class="company-name">{{"footerComponent.companyName" | translate}}</span>&nbsp;{{"footerComponent.copyrightText" | translate}}</p>
    </div>
    <div class="right-box">    
        <button id="ot-sdk-btn" class="ot-sdk-show-settings">Cookie Settings</button>
    </div>
</div>

<div fxHide.gt-sm="true" class="container">
    <button id="ot-sdk-btn" class="ot-sdk-show-settings">Cookie Settings</button>
    <p>&copy; <span class="company-name">{{"footerComponent.companyName" | translate}}</span>&nbsp;{{"footerComponent.copyrightText" | translate}}</p>
</div>