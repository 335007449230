export class AuthenticationRequest
{
    private _clientInformation: string = '';

    public constructor(clientInformation: string)
    {
        this.clientInformation = clientInformation;
    }

    public set clientInformation(clientInformation: string)
    {
        this._clientInformation = clientInformation;
    }

    public get clientInformation(): string
    {
        return this._clientInformation;
    }

    public toJson(): any
    {
        return {
            clientInformation: this.clientInformation
        };
    }
}