<div class="container" [ngClass]="businessLineBaseClassName+'-container'" [formGroup]="multipleTdssRequestForm">
    <img fxHide.lt-md="true" class="landing-image" [alt]="'landing-image'" [src]="landingImageUrl" />
    <div class="tds-request-container">
        <div>
            <p class="title">{{ "tdsRequestComponent.title" | translate}}</p>
        </div>
        <div class="form-row">
            <div class="form-row-item form-row-item-stretch">
                <div class="form-group">
                    <mat-label class="subtitle">{{ "tdsRequestComponent.preferredLanguage" | translate}}</mat-label>
                    <mat-form-field appearance="outline">
                        <mat-select class="custom-mat-select" formControlName="language"
                            (selectionChange)="onPreferredLanguageChanged($event)" [value]="preferredLanguage?.code">
                            <mat-select-trigger>
                                <div class="language-list-selected-box">
                                    <img class="language-list-selected-box-flag" [alt]="preferredLanguage?.countryCode?.toLowerCase() + '-flag'" [src]="preferredLanguage?.countryCode?.toLowerCase() !== 'engb' ? 'assets/images/flags/' + preferredLanguage?.countryCode?.toLowerCase() + '.png' : 'assets/images/flags/' + preferredLanguage?.countryCode?.toLowerCase() + '.svg'" />
                                    <p>{{ preferredLanguage?.label }}</p>
                                </div>
                            </mat-select-trigger>
                            <mat-option *ngFor="let language of preferredLanguages" value="{{language.code}}">
                                <div class="language-list-box">
                                    <img class="language-list-box-flag" [alt]="language.countryCode.toLowerCase() + '-flag'" [src]="language?.countryCode?.toLowerCase() !== 'engb' ? 'assets/images/flags/' + language?.countryCode?.toLowerCase() + '.png' : 'assets/images/flags/' + language?.countryCode?.toLowerCase() + '.svg'" />
                                    <p>{{language.label }}</p>
                                </div>
                            </mat-option>
                        </mat-select>
                        <mat-error
                            *ngIf="multipleTdssRequestForm.controls['language'].invalid && (multipleTdssRequestForm.controls['language'].dirty || multipleTdssRequestForm.controls['language'].touched)">
                            {{ getErrorMessage(multipleTdssRequestForm.controls['language'].errors) }}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="form-row-item form-row-item-stretch" *ngIf="!isExternal">
                <div class="form-group">
                    <mat-label class="subtitle">{{ "tdsRequestComponent.branding" | translate}}</mat-label>
                    <mat-form-field appearance="outline">
                        <mat-select class="custom-mat-select" formControlName="branding"
                            (selectionChange)="onPreferredLanguageChanged($event)">
                            <mat-option *ngFor="let branding of brandings" [value]="branding.code">
                                {{branding.label}}
                            </mat-option>
                        </mat-select>
                        <mat-error
                            *ngIf="multipleTdssRequestForm.controls['branding'].invalid && (multipleTdssRequestForm.controls['branding'].dirty || multipleTdssRequestForm.controls['branding'].touched)">
                            {{ getErrorMessage(multipleTdssRequestForm.controls['branding'].errors) }}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <hr />

        <br *ngIf="!isExternal" />

        <div *ngIf="!isExternal" class="upload-container">
            <p class="text"><a href="assets/static/TDS_Upload_Template.xlsx" download>{{
                    "tdsRequestComponent.uploadDescription" |
                    translate}}</a></p>
            <div class="form-row">
                <div class="form-row-item">
                    <button class="mat-button-inversed" (click)="fileInput.click()" mat-button>{{
                        "tdsRequestComponent.upload" |
                        translate}}
                        <input #fileInput class="custom-file-input" type="file" accept=".xlsx"
                            (change)="onFileUploaded($event)" hidden />
                    </button>
                </div>
                <div class="form-row-item" *ngIf="fileName">
                    <p class="text filename">{{fileName}}</p>
                </div>
                <div class="form-row-item" *ngIf="!fileName">
                    <p class="text filename">{{ "tdsRequestComponent.noFilesUploaded" | translate}}</p>
                </div>
            </div>
        </div>

        <br />

        <div>
            <div formArrayName="tdss">
                <div class="form">
                    <div *ngFor="let tdsForm of tdss.controls; first as isFirst; let index= index">
                        <div [formGroupName]="index">
                            <div class="form-row">
                                <div class="form-row-item" *ngIf="!isFirst" fxHide.lt-md="true">
                                    <img class="delete-tds-icon" [src]="'assets/icons/delete.png'"
                                        (click)="removeTdsRequest(tdsForm.value)" />
                                </div>
                                <div class="form-row-item form-row-item-stretch">
                                    <div class="form-group">
                                        <mat-label class="subtitle">{{ "tdsRequestComponent.articleNumber" | translate}}
                                            *
                                        </mat-label>
                                        <mat-form-field appearance="outline">
                                            <input formControlName="articleNumber" matInput
                                                (input)="onArticleNumberChanged($event)" />
                                            <mat-error
                                                *ngIf="tdsForm.get('articleNumber')!.invalid && (tdsForm.get('articleNumber')!.dirty || tdsForm.get('articleNumber')!.touched)">
                                                {{ getErrorMessage(tdsForm.get('articleNumber')!.errors) }}
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="form-row-item form-row-item-stretch">
                                    <div class="form-group">
                                        <mat-label class="subtitle">{{ "tdsRequestComponent.version" | translate}}
                                        </mat-label>
                                        <mat-form-field appearance="outline">
                                            <input formControlName="version" matInput
                                                (input)="onVersionChanged($event)" />
                                            <mat-error
                                                *ngIf="tdsForm.get('version')!.invalid && (tdsForm.get('version')!.dirty ||tdsForm.get('version')!.touched)">
                                                {{ getErrorMessage(tdsForm.get('version')!.errors) }}
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="form-row-item" fxHide.lt-md="true">
                                    <button class="mat-button-inversed" mat-button
                                        (click)="onShowTdsRequestClickedAsync(tdsForm.value)"
                                        [disabled]="!tdsForm.valid">{{
                                        "tdsRequestComponent.show" | translate}}</button>
                                </div>
                                <div class="form-row-item" *ngIf="checkIfTdsDownloadAttempted(index)">
                                    <mat-icon *ngIf="!checkIfTdsCouldBeDownloaded(index)" class="error-icon">error
                                    </mat-icon>
                                    <mat-icon *ngIf="checkIfTdsCouldBeDownloaded(index)" class="success-icon">
                                        check_circle</mat-icon>
                                </div>
                            </div>
                            <div class="form-row" fxHide.lt-md="true"
                                *ngIf="checkIfTdsDownloadAttempted(index) && !checkIfTdsCouldBeDownloaded(index) && tdsDownloadErrorMessage">
                                <div class="form-row-item">
                                    <p class="tds-download-error-message">{{tdsDownloadErrorMessage}}</p>
                                </div>
                            </div>
                            <div fxHide.gt-sm="true">
                                <div class="form-row">
                                    <div class="form-row-item">
                                        <button class="mat-button-inversed" mat-button
                                            (click)="onShowTdsRequestClickedAsync(tdsForm.value)"
                                            [disabled]="!tdsForm.valid">{{
                                            "tdsRequestComponent.show" | translate}}</button>
                                    </div>
                                    <div class="form-row-item" *ngIf="!isFirst">
                                        <img class="delete-tds-icon" [src]="'assets/icons/delete.png'"
                                            (click)="removeTdsRequest(tdsForm.value)" />
                                    </div>
                                    <div class="form-row-item"
                                        *ngIf="checkIfTdsDownloadAttempted(index) && !checkIfTdsCouldBeDownloaded(index) && tdsDownloadErrorMessage">
                                        <p class="tds-download-error-message">{{tdsDownloadErrorMessage}}</p>
                                    </div>
                                </div>
                                <br />
                            </div>
                        </div>
                    </div>
                </div>

                <label class="add-tds-label" (click)="addNewTdsRequest()" *ngIf="canAddTdsRequest()">+ {{
                    "tdsRequestComponent.addAnotherTDS" | translate}} ({{ "tdsRequestComponent.maximum" | translate}}
                    {{getMaxAmountOfTDSs()}})</label>
            </div>

            <br *ngIf="canAddTdsRequest()" />

            <hr />

            <br />

            <div>
                <div>
                    <mat-checkbox formControlName="sendAsMail">
                    </mat-checkbox>
                    <mat-label class="send-as-mail-label">{{ "tdsRequestComponent.receiveByEmail" | translate}}
                    </mat-label>
                </div>
                <br />
                <div *ngIf="multipleTdssRequestForm.value.sendAsMail && !isLoading">
                    <div class="form-group">
                        <mat-label class="subtitle">{{ "tdsRequestComponent.emailAddresses" | translate}} *</mat-label>
                        <p *ngIf="!isExternal">{{ "tdsRequestComponent.emailAddressesInfo" | translate}}</p>
                        <mat-form-field appearance="outline">
                            <input formControlName="emailAddress" matInput />
                            <mat-error
                                *ngIf="multipleTdssRequestForm.controls['emailAddress'].invalid && (multipleTdssRequestForm.controls['emailAddress'].dirty || multipleTdssRequestForm.controls['emailAddress'].touched)">
                                {{ getErrorMessage(multipleTdssRequestForm.controls['emailAddress'].errors) }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <button [class.mat-button-error]="errorMessage" mat-button (click)="onSendClickedAsync()"
                        [disabled]="!multipleTdssRequestForm.valid">{{
                        "tdsRequestComponent.send" | translate}}</button>
                </div>
            </div>

            <div *ngIf="!multipleTdssRequestForm.value.sendAsMail && !isLoading">
                <br />
                <button [class.mat-button-error]="errorMessage" mat-button (click)="onDownloadClickedAsync()"
                    [disabled]="!multipleTdssRequestForm.valid">{{
                    "tdsRequestComponent.download" |
                    translate}}</button>
            </div>

            <div *ngIf="errorMessage">
                <p *ngIf="errorMessage" class="error-message">
                    {{ errorMessage }}
                </p>
            </div>

            <div *ngIf="emailSuccessfullySent" class="success-message">
                <p class="success-title">
                    {{ "tdsRequestComponent.successfullySent" | translate}}
                </p>

                <p>
                    {{ "tdsRequestComponent.successfullySentMessage" | translate}}
                </p>
            </div>

            <div *ngIf="isLoading" class="loading-container">
                <mat-progress-spinner [mode]="'indeterminate'" [diameter]="25"></mat-progress-spinner>
            </div>


            <div>
                <br />
                <p class="terms-of-use-label-line">{{ "tdsRequestComponent.termsOfUseOne" | translate}}&nbsp;<span
                        class="terms-of-use-label" (click)="openTermsOfUseDialog()">{{
                        "tdsRequestComponent.termsOfUseTwo" |
                        translate}}</span></p>
            </div>
        </div>
    </div>