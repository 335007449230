import { Injectable } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { filter, ReplaySubject, takeUntil } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs/internal/Subject';

@Injectable({
  providedIn: 'root'
})

export class AuthenticationService
{
  public isExternalPortal$: Observable<boolean>;
  private _isExternalPortal$: ReplaySubject<boolean>;
  private _isExternalPortal!: boolean;

  private readonly _destroyAuthenticationSubscription$: Subject<void>;

  public constructor(
    private _msalService: MsalService,
    private _msalBroadcastService: MsalBroadcastService)
  {
    this._isExternalPortal$ = new ReplaySubject<boolean>(1);
    this.isExternalPortal$ = this._isExternalPortal$.asObservable();
    
    this._isExternalPortal = true;
    
    this._destroyAuthenticationSubscription$ = new Subject<void>();

    this.handleAuthentication();
  }

  public async loginAsync(): Promise<void>
  {
    await this._msalService.instance.loginRedirect();
  }

  public async logoutAsync(): Promise<void>
  {
    await this._msalService.instance.logout();
  }

  public destroyAuthenticationSubscription(): void
  {
    this._destroyAuthenticationSubscription$.next(undefined);
    this._destroyAuthenticationSubscription$.complete();
  }
  
  private setIsExternalPortal(): void
  {
    this._isExternalPortal = this._msalService.instance.getAllAccounts().length < 1;

    this._isExternalPortal$.next(this._isExternalPortal);
  }

  private handleAuthentication(): void
  {
    this._msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroyAuthenticationSubscription$)
      )
      .subscribe(() =>
      {
        this.setIsExternalPortal();
      });
  }
}
