import { SingleTdsResponse } from "./single-tds-response.model";

export class MultipleTdssResponse
{
    private _tdsResponses: SingleTdsResponse[] = [];

    public constructor(tdsResponses: SingleTdsResponse[])
    {
        this.tdsResponses = tdsResponses;
    }

    public set tdsResponses(tdsResponses: SingleTdsResponse[])
    {
        this._tdsResponses = tdsResponses;
    }

    public get tdsResponses(): SingleTdsResponse[]
    {
        return this._tdsResponses;
    }

    public static fromJson(json: any): MultipleTdssResponse
    {
        return new MultipleTdssResponse(
            (json.tdsResponses as any[]).map((tdsResponse) => SingleTdsResponse.fromJson(tdsResponse))
        );
    }
}