import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticationService } from 'src/app/authentication/services/authentication.service';
import { CookieStatementComponent } from '../cookie-statement/cookie-statement.component';
import { MainComponent } from '../main/main.component';
import { TermsOfUseComponent } from '../terms-of-use/terms-of-use.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit
{
  public constructor(
    public authenticationService: AuthenticationService,
    private _dialog: MatDialog) { }

  public ngOnInit(): void
  {
  }

  public openTermsOfUseDialog()
  {
    const dialogRef = this._dialog.open(TermsOfUseComponent);
  }

  public openCookieStatementDialog()
  {
    const dialogRef = this._dialog.open(CookieStatementComponent);
  }
}
