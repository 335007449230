import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, map } from 'rxjs';
import { AuthenticationResponse } from 'src/app/api-authentication/models/authentication-response.model';
import { ApiAuthenticationService } from 'src/app/api-authentication/services/api-authentication.service';
import { environment } from 'src/environments/environment';
import { MultipleTdssRequest } from '../models/multiple-tdss-request.model';
import { MultipleTdssResponse } from '../models/multiple-tdss-response.model';
import { SingleTdsRequest } from '../models/single-tds-request.model';
import { SingleTdsResponse } from '../models/single-tds-response.model';
import {TdsRequestApiConstants} from './constants/tds-request-api.constants';

@Injectable({
    providedIn: 'root'
})

export class TdsRequestDataService
{
    public constructor(
        private _apiAuthenticationService: ApiAuthenticationService,
        private _httpClient: HttpClient)
    {
    }

    public async getSingleTdsAsync(singleTdsRequest: SingleTdsRequest): Promise<SingleTdsResponse>
    {
        const authenticationResponse: AuthenticationResponse = await this._apiAuthenticationService.authenticateAsync();

        const requestOptions: any = {
            headers: new HttpHeaders({
                'Authorization': `Bearer ${authenticationResponse.accessToken}`
            })
        };

        return await lastValueFrom(this._httpClient.post(`${environment.apiUrl}/${TdsRequestApiConstants.GET_SINGLE_TDS}`, singleTdsRequest.toJson(), requestOptions)
            .pipe(
                map((json: any): SingleTdsResponse => SingleTdsResponse.fromJson(json))
            ));
    }

    public async getMultipleTdssAsync(multipleTdssRequest: MultipleTdssRequest): Promise<MultipleTdssResponse>
    {
        const authenticationResponse: AuthenticationResponse = await this._apiAuthenticationService.authenticateAsync();

        const requestOptions: any = {
            headers: new HttpHeaders({
                'Authorization': `Bearer ${authenticationResponse.accessToken}`
            })
        };

        return await lastValueFrom(this._httpClient.post(`${environment.apiUrl}/${TdsRequestApiConstants.GET_MULTIPLE_TDS}`, multipleTdssRequest.toJson(), requestOptions)
            .pipe(
                map((json: any): MultipleTdssResponse => MultipleTdssResponse.fromJson(json))
            ));
    }
}