import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { TranslationService } from './services/translation.service';

export function HttpLoaderFactory(http: HttpClient)
{
  return new TranslateHttpLoader(http,
    './assets/i18n/ui/',
    '.json');
}

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: environment.defaultUiLanguageCode,
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
    }),
  ],
  providers: [TranslationService],
  exports: [TranslateModule]
})

export class TranslationModule { }