// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  clientAuthenticationIssuer: '7246aeef-14bd-42d0-b585-7465b62b385e',
  clientAuthenticationAudience: '7246aeef-14bd-42d0-b585-7465b62b385e',
  clientAuthenticationApplicationId: '083c63f2-2b5b-42e7-a4dd-e77f9f8ba896',
  clientAuthenticationKey: "bNi6ER6I32MsaEeqfCDA3PY4aRT5xDpudiMHueKwomYJNoQ234IoSGXyLTxN",
  clientAuthenticationTokenExpiresAfterMinutes: 30,

  clientId: '5d9632ef-ade9-4f8e-886c-c70eeecb6fe8',
  authority: 'https://login.microsoftonline.com/ac0173a8-d04e-4a4a-a726-75a9ac42ee2f',
  redirectUri: 'https://productinfo-qa.vandemoortele.com',
  postLogoutRedirectUri: 'https://productinfo-qa.vandemoortele.com',
  // redirectUri: 'http://localhost:4200',
  // postLogoutRedirectUri: 'http://localhost:4200',

  apiUrl: 'https://productinfo-be-qa.vandemoortele.com/api',
  //apiUrl: 'https://localhost:7144/api',

  googleAnalyticsMeasurementId:'G-2EXHY3D6FB',

  cookieProId:'172e95e3-d29f-46b9-a831-f05372695589-test',

  mailAddressSeparator: ';',

  companyThemes: [
    {
      label: 'Vandemoortele',
      code: 'corporate'
    },
    {
      label: 'Croustico',
      code: 'croustico'
    }
  ],
  defaultCompanyTheme: 'corporate',

  businessLines: [
    {
      label: 'Bakery',
      code: 'bp'
    },
    {
      label: 'Margarines, Culinary Oils and Fats',
      code: 'mcof'
    }, {
      label: 'General',
      code: 'general'
    }
  ],
  defaultBusinessLine: 'general',

  uiLanguages: [
    {
      label: 'English',
      code: 'en_GLOBAL',
      countryCode: 'GB'
    },
    {
      label: 'Dutch',
      code: 'nl_BE',
      countryCode: 'NL'
    },
    {
      label: 'French',
      code: 'fr_FR',
      countryCode: 'FR'
    },
    {
      label: 'Czech',
      code: 'cs_CZ',
      countryCode: 'CS'
    },
    {
      label: 'Danish',
      code: 'da_DK',
      countryCode: 'DA'
    },
    {
      label: 'German',
      code: 'de_DE',
      countryCode: 'DE'
    },
    {
      label: 'Spanish',
      code: 'es_ES',
      countryCode: 'ES'
    },
    {
      label: 'Finnish',
      code: 'fi_FI',
      countryCode: 'FI'
    },
    {
      label: 'Hungarian',
      code: 'hu_HU',
      countryCode: 'HU'
    },
    {
      label: 'Italian',
      code: 'it_IT',
      countryCode: 'IT'
    },
    {
      label: 'Polish',
      code: 'pl_PL',
      countryCode: 'PL'
    },
    {
      label: 'Portuguese',
      code: 'pt_PT',
      countryCode: 'PT'
    },
    {
      label: 'Swedish',
      code: 'sv_SE',
      countryCode: 'SV'
    },
    {
      label: 'Slovakian',
      code: 'sk_SK',
      countryCode: 'SK'
    }
  ],
  defaultUiLanguageCode: 'en_GLOBAL',

  tdsLanguages_onHold: [
    {
      label: 'English (US)',
      code: 'en_US',
      countryCode: 'EN'
    },
  ],
  tdsLanguages: [
    {
      label: 'English',
      code: 'en_GLOBAL',
      countryCode: 'ENGB'
    },
    {
      label: 'English (GB)',
      code: 'en_GB',
      countryCode: 'GB'
    },
    {
      label: 'Dutch (NL)',
      code: 'nl_NL',
      countryCode: 'NL'
    },
    {
      label: 'Dutch (BE)',
      code: 'nl_BE',
      countryCode: 'BE'
    },
    {
      label: 'French (BE)',
      code: 'fr_BE',
      countryCode: 'BE'
    },
    {
      label: 'French (FR)',
      code: 'fr_FR',
      countryCode: 'FR'
    },
    {
      label: 'Czech',
      code: 'cs_CZ',
      countryCode: 'CS'
    },
    {
      label: 'Danish',
      code: 'da_DK',
      countryCode: 'DA'
    },
    {
      label: 'German',
      code: 'de_DE',
      countryCode: 'DE'
    },
    {
      label: 'Spanish',
      code: 'es_ES',
      countryCode: 'ES'
    },
    {
      label: 'Finnish',
      code: 'fi_FI',
      countryCode: 'FI'
    },
    {
      label: 'Hungarian',
      code: 'hu_HU',
      countryCode: 'HU'
    },
    {
      label: 'Italian',
      code: 'it_IT',
      countryCode: 'IT'
    },
    {
      label: 'Polish',
      code: 'pl_PL',
      countryCode: 'PL'
    },
    {
      label: 'Portuguese',
      code: 'pt_PT',
      countryCode: 'PT'
    },
    {
      label: 'Swedish',
      code: 'sv_SE',
      countryCode: 'SV'
    },
    {
      label: 'Slovakian',
      code: 'sk_SK',
      countryCode: 'SK'
    }
  ],
  defaultTdsLanguageCode: 'en_GLOBAL',

  maxTdsRequestsForInternal: 40,
  maxTdsRequestsForExternal: 10,

  companyThemeQueryParameter: 'c',
  languageQueryParameter: 'l',
  documentlanguageQueryParameter: "dl",
  articleIdQueryParameter: 'p',
  articleIdUnencodedQueryParameter: 'pn',
  versionQueryParameter: 'v',
  versionUnencodedQueryParameter:'vn',
  emailQueryParameter: 'e',
  businessLineQueryParameter: 'b',
  articleBrandQueryParameter: 'br'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
