import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/authentication/services/authentication.service';
import { AppRoutingConstants } from 'src/app/routing/constants/app-routing-constants';
import { BusinessLine } from 'src/app/shared/models/business-line.model';
import { BusinessLineService } from 'src/app/shared/services/business-line.service';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})

export class PageNotFoundComponent implements OnInit
{
  public isExternalPortal!: boolean;
  public siteBusinessLine!: BusinessLine;
  public businessLineBaseClassName!: string;

  public imageUrl: string;

  public constructor(
    public authenticationService: AuthenticationService,
    private _businessLineService: BusinessLineService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _title:Title
  )
  {
    this._title.setTitle("TDS Portal - 404");

    this.imageUrl = '';

    this.initializeIsExternalPortal();
  }

  public ngOnInit(): void
  {
  }

  public onBackToHomeClicked(): void
  {
    const queryParams = this._route.snapshot.queryParams;

    this._router.navigate([AppRoutingConstants.EMPTY], { queryParams: queryParams })
      .then(() => window.location.reload()); // Have to reload because mainComponent is otherwise not recreated
  }

  private initializeIsExternalPortal(): void
  {
    this.authenticationService.isExternalPortal$.subscribe(result =>
    {
      this.isExternalPortal = result;

      this.initializeSiteBusinessLine();
    });
  }

  private initializeSiteBusinessLine(): void
  {
    this._businessLineService.siteBusinessLine$.subscribe(result =>
    {
      this.siteBusinessLine = result;

      this.imageUrl = this.getPageNotFoundImageUrl();

      this.businessLineBaseClassName = this.getBusinessLineBaseClassName();
    });
  }

  private getBusinessLineBaseClassName(): string
  {
    return this._businessLineService.getBusinessLineBaseClassName(this.isExternalPortal);
  }

  private getPageNotFoundImageUrl(): string
  {
    if (!this.isExternalPortal)
    {
      return `assets/images/page-not-found/general-internal.png`;
    }

    return `assets/images/page-not-found/${this.siteBusinessLine.code}-external.png`;
  }
}