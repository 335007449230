import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';
import { lastValueFrom, map } from 'rxjs';
import { AuthenticationRequest } from 'src/app/api-authentication/models/authentication-request.model'
import { AuthenticationResponse } from '../models/authentication-response.model';
import { ApiAuthenticationApiConstants } from './constants/api-authentication-api-constants';

@Injectable({
  providedIn: 'root'
})

export class ApiAuthenticationService
{
  public constructor(private _httpClient: HttpClient)
  {
  }

  public async authenticateAsync(): Promise<AuthenticationResponse>
  {
    const authenticationRequest = new AuthenticationRequest(this.createAccessToken());

    return await lastValueFrom(this._httpClient.post(`${environment.apiUrl}/${ApiAuthenticationApiConstants.AUTHENTICATE}`, authenticationRequest.toJson())
      .pipe(
        map((json: any): AuthenticationResponse => AuthenticationResponse.fromJson(json))
      ));
  }

  private createAccessToken(): string
  {
    const headers = {
      'alg': 'HS512',
      'typ': 'JWT',
      'no-cache': 'no-cache'
    };

    const encodedHeaders = this.base64UrlCryptoJs(CryptoJS.enc.Utf8.parse(JSON.stringify(headers)));

    var date = new Date();

    const issuedAt = Math.floor(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(),
      date.getUTCHours(), date.getUTCMinutes() - 1, date.getUTCSeconds()) / 1000);

    const notBefore = issuedAt;

    const expiresAt = issuedAt + (environment.clientAuthenticationTokenExpiresAfterMinutes * 60);

    const claims = {
      'aud': environment.clientAuthenticationAudience,
      'iss': environment.clientAuthenticationIssuer,
      'iat': issuedAt.toString(),
      'nbf': notBefore.toString(),
      'exp': expiresAt.toString(),
      'client_id': environment.clientAuthenticationApplicationId
    };

    const encodedPayload = this.base64UrlCryptoJs(CryptoJS.enc.Utf8.parse(JSON.stringify(claims)));

    const key = encodeURIComponent(environment.clientAuthenticationKey);

    const signature = CryptoJS.HmacSHA512(`${encodedHeaders}.${encodedPayload}`, key);

    const encodedSignature = this.base64UrlCryptoJs(signature);

    const jwt = `${encodedHeaders}.${encodedPayload}.${encodedSignature}`;

    return jwt;
  }

  private base64UrlCryptoJs(stringToEncode: any): string
  {
    let encodedSource = CryptoJS.enc.Base64.stringify(stringToEncode);

    encodedSource = encodedSource.replace(/=+$/, '');
    encodedSource = encodedSource.replace(/\+/g, '-');
    encodedSource = encodedSource.replace(/\//g, '_');

    return encodedSource;
  }
}