import { Injectable, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ReplaySubject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { Language } from '../../models/language.model';
import { QueryParameterUtils } from '../../utils/query-parameter.utils';
import { DOCUMENT } from '@angular/common';

declare const window: any;
@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  public siteLanguages: Language[];

  public siteLanguage$: Observable<Language>;
  private _siteLanguage$: ReplaySubject<Language>;
  private _siteLanguage!: Language;

  private readonly _languageLocalStorageKey = 'tdsUiLanguage';

  public constructor(
    @Inject(DOCUMENT) private _document: Document,
    private _translationService: TranslateService,
    private _route: ActivatedRoute,
    private _router: Router
  ) {
    this.siteLanguages = [];

    this._siteLanguage$ = new ReplaySubject<Language>(1);
    this.siteLanguage$ = this._siteLanguage$.asObservable();

    this.initializeSiteLanguages();
    this.initializeUILanguage();
    this.handleQueryParameters();
  }

  public tryChangeSiteLanguage(languageCode: string): boolean {
    try {
      const selectedLanguage: Language | undefined = this.siteLanguages.find(
        (language) => language.code.toLowerCase() === languageCode.toLowerCase()
      );
      
      if (selectedLanguage) {
        this._siteLanguage = selectedLanguage;

        this._translationService.use(this._siteLanguage.code);

        this._siteLanguage$.next(this._siteLanguage);

        localStorage.setItem(
          this._languageLocalStorageKey,
          this._siteLanguage.code
        );

        const countryCode = this._siteLanguage.countryCode.toLowerCase();
        if (
          countryCode === 'da' ||
          countryCode === 'gb' ||
          countryCode === 'fi' ||
          countryCode === 'hu'
        ) {
          this._document.documentElement.lang = 'en';
        } else {
          this._document.documentElement.lang =
            this._siteLanguage.countryCode.toLowerCase();
        }

        window.OneTrust.changeLanguage(this._document.documentElement.lang);
        return true;
      }

      return false;
    } catch (exception: any) {
      return false;
    }
  }

  private initializeSiteLanguages(): void {
    for (const language of environment.uiLanguages) {
      this.siteLanguages.push(
        new Language(language.label, language.code, language.countryCode)
      );
    }

    this.siteLanguages.sort((a, b) => a.label.localeCompare(b.label));
  }

  private initializeUILanguage(): void {
    const languageStorage = localStorage.getItem(this._languageLocalStorageKey);
    if (languageStorage) {
      this.tryChangeSiteLanguage(languageStorage);
    } else {
      this.tryChangeSiteLanguage(environment.defaultUiLanguageCode);
    }
  }

  private handleQueryParameters(): void {
    this._route.queryParams.subscribe((params) => {
      if (params[environment.languageQueryParameter]) {
        const languageParam = QueryParameterUtils.fromBase64(
          params[environment.languageQueryParameter]
        );
        const selectedLanguage: Language | undefined = this.siteLanguages.find(
          (language) =>
            language.countryCode.toLowerCase() === languageParam.toLowerCase()
        );
        if (selectedLanguage) {
          this.tryChangeSiteLanguage(selectedLanguage?.code);
        }
      }
    });
  }
}
